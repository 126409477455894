import React from "react";
import { Link } from "gatsby";

import { Navbar, Nav } from "react-bootstrap";

const CustomNavbar = ({ title, location }) => {
  return (
    <Navbar variant="pills" expand="sm">
      <Link to="/">
        <Navbar.Brand>{title}</Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav variant="pills" activeKey={location.pathname} className="ms-auto">
          <Nav.Item>
            <Link to="/">
              <Nav.Link as="span" eventKey="/">
                Main
              </Nav.Link>
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/about/">
              <Nav.Link as="span" eventKey="/about/">
                About
              </Nav.Link>
            </Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
};

export default CustomNavbar;
